"use client";

import { BannerSection } from "./components/sections/BannerSection";
import { PrimaryButton } from "@/components/common/buttons/PrimaryButton";
import { Title } from "@/components/common/typography/Title";

export default function NotFound() {
  return (
    <main>
      <BannerSection title="Page non trouvée" />
      <div className="flex flex-col items-center gap-12 bg-secondary px-4 py-20 ph:px-24 md:px-48 lg:px-72 xl:px-96">
        <Title
          text="Erreur 404"
          position="center"
          textPosition="left"
          color="primary"
        />
        <p className="text-center text-primary">
          La page que vous recherchez n&apos;existe pas.
        </p>
        <PrimaryButton text="Retourner à l'accueil" navigateTo="/" />
      </div>
    </main>
  );
}
